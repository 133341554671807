.dropdown-item {
  a {
    color: $dark;
  }

  &:hover {
    background-color: transparent;

    a {
      color: $blue-light;
    }
  }
}

.selection-to-open {
  right: 20px;
  top: 20px;
  position: absolute;
}
