.services-cards {
  @extend .d-flex;

  align-items: center;
  justify-content: center;
  height: 200px;
  border: 1px solid;
  margin-left: 15px;
  text-align: center;
  color: #fff;
  cursor: pointer;
  border-radius: 10px;

  &:first-child {
    margin-left: 0 !important;
  }

  @include media-breakpoint-down(md) {
    margin-left: 0 !important;
    margin-bottom: 15px;
  }

  &:hover {
    color: #fff !important;
    font-weight: 900;
  }

  &.card-01 {
    background-image: url($assets-folder + "images/services-01.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  &.card-02 {
    background-image: url($assets-folder + "images/services-02.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  &.card-03 {
    background-image: url($assets-folder + "images/services-03.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  &.card-04 {
    background-image: url($assets-folder + "images/services-04.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  &.card-05 {
    background-image: url($assets-folder + "images/services-05.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  &.card-06 {
    background-image: url($assets-folder + "images/services-06.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  &.card-07 {
    background-image: url($assets-folder + "images/services-07.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
}

.cookies-container {
  @extend .d-flex;
  @extend .flex-column;
  @extend .align-items-center;

  position: absolute;
  bottom: 25px;
  left: 0;
  right: 0;
  margin-top: 303px;

  .cookies-container__col {
    background: rgba(255, 255, 255, 0.63);;
    border-radius: 10px;
    padding: 10px;
  }
}

.justify-content-space-evenly {
  justify-content: space-evenly;
}

.scrolling-wrapper{
	overflow-x: auto;
}

.z-index-500 {
  z-index: 500 !important;
}
