body {
  margin: 0;
  font-family: 'Raleway', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #ffffff;
}

.content {
  @include media-breakpoint-up(md) {
    height: calc(100vh - 70px) !important;
  }
}

html,
body,
#root {
  height: 100%;
}

.container-lg-720 {
  @include media-breakpoint-up(md) {
    max-width: 720px;
  }
}

.container-lg-880 {
  @include media-breakpoint-up(md) {
    max-width: 880px;
  }
}

.full-height-with-header {
  min-height: calc(100vh - 70px);
}
