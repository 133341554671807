.profile-menu {
  background: $primary !important;
  border: none !important;
  outline: none !important;
  border-radius: 50rem !important;
  padding: 5px 25px;

  &:after {
    display: none;
  }

  &:focus {
    box-shadow: none !important;
  }
}
