.company-logos {
  padding-bottom: 30px;
  margin-bottom: 10px;

  &.carousel {
    .carousel-indicators {
      bottom: -20px !important;

      li {
        background-color: #393939 !important;
        border-radius: 50%;
        width: 10px;
        height: 10px;
      }
    }

    .carousel-control-prev, .carousel-control-next {
      align-items: flex-end !important;
      height: 27px;
      position: absolute;
      top: auto;
    }
  }
}

.testimonials {
  padding-bottom: 50px;
  margin-bottom: 30px;
  min-height: 870px !important;

  &.carousel {
    .carousel-indicators {
      bottom: -20px !important;

      li {
        border-radius: 50%;
        width: 10px;
        height: 10px;
      }
    }

    .carousel-control-prev, .carousel-control-next {
      align-items: flex-end !important;
    }
  }
}
