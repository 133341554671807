.shadow-post {
  -webkit-box-shadow: 0 0 3px 1px rgba(162, 162, 162, 0.25);
  -moz-box-shadow: 0 0 3px 1px rgba(162, 162, 162, 0.25);
  box-shadow: 0 0 3px 1px rgba(162, 162, 162, 0.25);
}

.no-shadow-post-md-down {
  @include media-breakpoint-down(md) {
    box-shadow: none;
  }
}
