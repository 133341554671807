input, select {
  &:focus {
    box-shadow: none !important;
  }
}

.input-outline-only-bottom {
  border: none;
  border-bottom: 1px solid $light-tone-3;
  border-radius: 0;
  padding-left: 0;
  background: initial;

  &[readonly] {
    background-color: transparent;
  }
}

.input-without-outline {
  border: none;
}

.has-search {
  .form-control {
    padding-left: 2.375rem;
  }

  .form-control-feedback {
    position: absolute;
    display: block;
    width: 2.375rem;
    height: 2.375rem;
    line-height: 2.375rem;
    text-align: center;
    pointer-events: none;
    color: #aaa;
  }
}

.select-outline-only-bottom {
  border: none;
  border-bottom: 1px solid;
  border-radius: 0;
  padding-left: 0;

  &.secondary {
    border-bottom: 1px solid $secondary !important;
  }

  &.light-tone-2 {
    border-bottom: 1px solid $light-tone-2 !important;
  }

  &.light-tone-3 {
    border-bottom: 1px solid $light-tone-3 !important;
  }
}

.react-select-outline-only-bottom {

  .select__control {
    border: none;
    border-bottom: 1px solid $light-tone-3 !important;
    border-radius: 0;

    .select__value-container {
      padding-left: 0;
    }

    &.select__control--is-focused {
      box-shadow: none;
    }
  }
}

.after-select {
  position: absolute;
  right: 23px;
  top: 10px;
  background-color: #fff;
  color: #ccc;
  font-size: .7rem;
  width: 20px;
  height: 20px;
  text-align: center;

  &.without-label {
    top: 13px !important;
    right: 6px !important;
  }
}

.delete-option-input {
  @extend .text-light-tone-2;
  position: absolute;
  top: 10px;
  right: 0;
}

.css-1rhbuit-multiValue {
  background-color: lighten($primary, 30%) !important;
}

.ck.ck-balloon-panel {
  z-index: 1050 !important;
}
