.modal-header {
  border-bottom: 1px solid $light-tone-1 !important;
}

.modal-footer {
  border-top: 1px solid $light-tone-1 !important;
}

.popover-notifications {
  @extend .container-fluid;
  @extend .rounded-full-10px;
  @extend .shadow-post;
  @extend .bg-white;
  @extend .mb-3;
  @extend .p-0;

  width: 330px;
  position: absolute;
  top: 80px;
  right: 35px;
  z-index: 1;

  @include media-breakpoint-down(md) {
    width: calc(100% - 27px);
    top: 84px;
    right: 15px;
    margin-bottom: 0 !important;
  }
}
