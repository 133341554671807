.bg-warning-light {
  background: lighten($warning, 45%);
}

.bg-success-light {
  background: lighten($success, 55%);
}

.bg-info-light {
  background: lighten($info, 55%);
}

.bg-our-team {
  background-image: url($assets-folder + "images/background-our-team.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.bg-testimonials {
  background-image: url($assets-folder + "images/background-testimonials.jpg");
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
}

.bg-main {
  background-image: url($assets-folder + "images/background-main.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.bg-about {
  background-image: url($assets-folder + "images/background-about.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.bg-ignacio {
  background-image: url($assets-folder + "images/background-ignacio.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.bg-alex {
  background-image: url($assets-folder + "images/background-alex.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.bg-christian {
  background-image: url($assets-folder + "images/background-christian.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.bg-george {
  background-image: url($assets-folder + "images/background-george.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.modal-menu {
  .modal-content {
    @extend .rounded-full-10px;

    background-color: #ffffff8a;
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(3px);
  }
}

.bg-our-team-single {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 350px;
}
