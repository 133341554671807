a {
  &:hover {
    text-decoration: none;
  }
}

.header-menu {
  .link {
    color: #fff;
    border-radius: 6px;
    padding: 5px 15px;

    &.active {
      color: #000;
      background: #ffffff7a;
    }
  }
}
