@each $name, $value in $theme-colors {
  .border-bottom-#{$name} {
    border-bottom: 1px solid $value !important;
  }
}

.border-separator {
  border-color: $light-tone-1 !important;
}

.border-bottom-separator {
  border-bottom: 1px solid $light-tone-1;
}

.border-top-separator {
  border-top: 1px solid $light-tone-1;
}

.full-rounded {
  border-radius: 50% !important;
}

.with-border-separated {

  &::after {
    content: '';
    position: absolute;
    top: -8px;
    left: -8px;
    right: -8px;
    bottom: -8px;
    border: #FFFFFF 1px solid;
    border-radius: 50% !important;
  }

  &.dark::after {
    border: #000000 1px solid !important;
  }
}

.rounded-full-10px {
  border-radius: 10px !important;
}

.rounded-top-10px {
  border-radius: 10px 10px 0 0 !important;
}

.rounded-bottom-10px {
  border-radius: 0 0 10px 10px !important;
}

.rounded-right-10px {
  border-radius: 0 10px 10px 0 !important;
}

.rounded-bottom-right-10px {
  border-radius: 0 0 10px 0 !important;
}

.rounded-top-menu {
  border-radius: 28px 28px 0 0 !important;
}

.rounded-bottom-menu {
  border-radius: 0 0 28px 28px !important;
}

.border-left {

  &-2 {
    border-left: 2px solid !important;
  }
}

.rounded-0-sm-only {
  @include media-breakpoint-down(md) {
    border-radius: 0 !important;
  }
}

.border-line {
  position: absolute;

  &.left {
    width: 1px;
    top: -75px;
    bottom: 83px;
    background-image: linear-gradient(to top, transparent 50%, $blue-light 50%);
    background-size: 100px 25px;

    @include media-breakpoint-down(md) {
      left: 20px;
      top: -72px;
    }
  }

  &.bottom-half {
    width: calc(50% - 160px);
    height: 1px;
    bottom: 0;
    left: 70px;
    background-image: linear-gradient(to right, transparent 50%, $blue-light 50%);
    background-size: 30px 100%;

    @include media-breakpoint-down(md) {
      left: 90px;
    }
  }

  &.center {
    width: 1px;
    height: 200px;
    background-image: linear-gradient(to top, transparent 50%, $blue-light 50%);
    background-size: 100px 25px;
  }
}

.border-bottom-left-radius {
  position: absolute;
  left: -26px;
  height: 98px;
  bottom: -20px;

  @include media-breakpoint-down(md) {
    left: -5px;
  }
}

.border-top-right-radius {
  position: absolute;
  top: -10px;
  right: 49%;
  height: 98px;

  @include media-breakpoint-down(md) {
    right: 48%;
  }
}
