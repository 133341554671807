@import "../../../../node_modules/bootstrap/scss/bootstrap";
@import "env";

$dark: #58595b;
$light-tone-5: darken($light, 60%);
$light-tone-4: darken($light, 40%);
$light-tone-3: darken($light, 15%);
$light-tone-2: darken($light, 7%);
$light-tone-1: darken($light, 3%);
$light-less-5: lighten($light, 2.5%);
$light-less-4: lighten($light, 2%);
$light-less-3: lighten($light, 1.5%);
$light-less-2: lighten($light, 1%);
$light-less-1: lighten($light, 0%);
$light: #E6E8EB;
$white: #fff;
$primary: #f95e36;
$secondary: #f6c457;
$tertiary: #7B31F1;
$blue-light: #04b2d9;
$blue-lp: #1C66AA;
$bg-preview-container: #FBFBFB;
$border-preview-container: #E0E0E0;
$gray-faint: #FDFEFF; // background posts page

$theme-colors: (
        "primary": $primary,
        "secondary": $secondary,
        "tertiary": $tertiary,
        "info": $blue-light,
        "blue-lp": $blue-lp,
        "dark": $dark,
        "light-tone-5": $light-tone-5,
        "light-tone-4": $light-tone-4,
        "light-tone-3": $light-tone-3,
        "light-tone-2": $light-tone-2,
        "light-tone-1": $light-tone-1,
        "light-less-5": $light-less-5,
        "light-less-4": $light-less-4,
        "light-less-3": $light-less-3,
        "light-less-2": $light-less-2,
        "light-less-1": $light-less-1,
        "light": $light,
        "gray-faint": $gray-faint,
);

@import "../../../../node_modules/bootstrap";
