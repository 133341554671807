.navbar-fixed {
  @extend .position-fixed;
  @extend .w-100;
  @extend .z-index-500;

  &.with-bg {
    background-color: #00000085;
  }
}

.nav-pills {
  padding: 10px;
  border: 1px solid #cecfcf;
  border-radius: 0.25rem;

  .nav-item {
    width: 100%;
  }

  .nav-link.active {
    color: #fff !important;
    background: $primary;
  }
}
