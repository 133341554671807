$padding-sizes: (
  6: 5rem,
  7: 7rem,
  8: 9rem,
  9: 11rem,
  10: 13rem,
) !default;

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    @each $size, $value in $padding-sizes {
      .p#{$infix}-#{$size} {
        padding: $value !important;
      }
      .pt#{$infix}-#{$size} {
        padding-top: $value !important;
      }
      .py#{$infix}-#{$size} {
        padding-top: $value !important;;
        padding-bottom: $value !important;;
      }
      .px#{$infix}-#{$size} {
        padding-left: $value !important;;
        padding-right: $value !important;;
      }

      .mt#{$infix}-#{$size} {
        margin-top: $value;
      }
    }
  }
}

.mt-less-100 {
  margin-top: -100px;

  @include media-breakpoint-down(sm) {
    margin-top: 0;
  }
}

.ml-less-20 {
  margin-left: -20px;
}

.mb-mobile {
  margin-bottom: 4.5rem;

  @include media-breakpoint-up(md) {
    margin-bottom: 0;
  }
}
