.sidenav {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 3;
  top: 0;
  left: -500px;
  overflow-x: hidden;
  transition: 0.5s;

  section {
    background-color: $light;
    height: 100%;
    width: 70%;
    box-shadow: 0 0 10px 3px rgba(209,209,209,0.25);
    overflow-x: hidden;
  }

  .closebtn {
    position: absolute;
    top: 14px;
    right: 20%;
    font-size: 1.5rem;
    margin-left: 50px;
    color: #fff;
  }
}

@media screen and (max-height: 450px) {
  .sidenav {padding-top: 15px;}
  .sidenav a {font-size: 18px;}
}

.sidenav-background {
  background-color: rgba(0, 0, 0, 0.47);
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  display: none;

  &.show {
    display: block;
  }
}
