.object-fit-cover {
  -o-object-fit: cover;
  object-fit: cover;
}

.img-profile-profile-ss {
  height: 1.2rem;
  width: 1.2rem;
}

.img-profile-profile-s {
  height: 2rem;
  width: 2rem;
}

.img-profile-profile-sm {
  height: 2.5rem;
  width: 2.5rem;
}

.img-profile-profile-m {
  height: 3.5rem;
  width: 3.5rem;
}

.img-profile-profile-2m {
  height: 4.5rem;
  width: 4.5rem;
}

.img-profile-profile-lg {
  height: 7.5rem;
  width: 7.5rem;
}

.icon-linkedin {
  position: absolute;
  bottom: 16px;
  right: -70px;
  width: 50px;

    @include media-breakpoint-down(md) {
      bottom: 65px;
      right: 27px;
    }
}

.img-profile-header {
    @include media-breakpoint-down(md) {
      width: 150px !important;
    }
}

.size-100 {
  width: 100px;
}
