.min-h-100 {
  min-height: 100%;
}

.full-height-home {
  min-height: 100vh;
}

.full-height-home-md-down {
  @include media-breakpoint-down(md) {
    min-height: 100vh;
  }
}

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.overflow-x-scroll {
  overflow-x: scroll;
}

.react-confirm-alert-overlay {
  z-index: 10000 !important;
}

.w-max-content {
  width: max-content;
}
