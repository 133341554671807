.btn-outline-primary {

  &:hover {
    color: $white;
  }
}

.badge {
  &-rounded {
    height: 25px;
    width: 25px;
    border-radius: 50% !important;
    display: flex !important;
    align-items: center;
    justify-content: center;
    font-size: 0.8rem !important;
  }
}

// Generate series of `.btn-*-inline-block` (plus `.btn-inline-block` itself)
@each $breakpoint in map-keys($grid-breakpoints) {
  $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

  .btn#{$infix}-inline-block {
    @include media-breakpoint-up($breakpoint) {
      display: inline-block;
      width: auto;
    }
  }
}

.edit-card {
  @extend .text-info;
  @extend .font-size-4;
  @extend .cursor-pointer;
  position: absolute;
  right: 15px;
  top: 15px;
  z-index: 1;
}

.scrollTop-btn {
  position: fixed;
  right: 25px;
  bottom: 20px;
  cursor: pointer;
  width: 50px;
  opacity: 0.5;
}
