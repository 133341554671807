.tooltip-where-post {

	.tooltip-inner {
		max-width: 290px;
		padding-left: 0.5rem;
		padding-right: 0.5rem;
		font-size: 0.7rem;
		color: $light-tone-4;
		text-align: left;
		background-color: $white;
		border-radius: .25rem;
		border: 1px solid $secondary;
	}

	.arrow {
		&:before {
			border-right-color: $secondary;
		}
		&:after {
			content: " ";
			position: absolute;
			right: 100%;
			left: -5px;
			border-width: 6px;
			border-style: solid;
			border-color: transparent white transparent transparent;
		}
	}

	&.border-info {
		.tooltip-inner {
			border: 1px solid $cyan !important;
			color: $dark;
		}

		.arrow {
			&:before {
				border-right-color: $cyan;
			}
		}
	}
}
