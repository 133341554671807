$font-size-xs:  0.75rem !default;
$font-size-xl:  1.5rem !default;
$font-size-xxl: 1.75rem !default;
$font-size-3xl: 2rem !default;
$font-size-4xl: 2.5rem !default;
$font-size-5xl: 3rem !default;
$font-size-6xl: 4rem !default;
$font-size-7xl: 7rem !default;
$font-size-big: 12rem !default;

$font-sizes: (
  1: $font-size-xs,
  2: $font-size-sm,
  3: $font-size-base,
  4: $font-size-lg,
  5: $font-size-xl,
  6: $font-size-xxl,
  7: $font-size-3xl,
  8: $font-size-4xl,
  9: $font-size-5xl,
  10: $font-size-6xl,
  11: $font-size-7xl,
  12: $font-size-big
) !default;

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    @each $size, $value in $font-sizes {
      .font-size#{$infix}-#{$size} {
        font-size: $value;
      }
    }
  }
}

.library-title__text-ellipsis {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;

  @include media-breakpoint-down(sm) {
    -webkit-line-clamp: 1;
  }
}

.library-description__text-ellipsis {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;

  @include media-breakpoint-down(sm) {
    -webkit-line-clamp: 2;
  }
}

.white-space__pre-line {
  white-space: pre-line;
}

.text-decoration-underline {
  text-decoration: underline;
}
