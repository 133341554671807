.label-right {
  right: -24px;

  @include media-breakpoint-down(md) {
    right: -8px;
  }
}

.top-less-8 {
  top: -8px;
}
